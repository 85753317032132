YP.PricingExeperience = function() {

    var range            = $(".contact_input");

    const rangeInputs = document.querySelectorAll('input[type="range"]')

    function handleInputChange(e) {
        let target = e.target
        if (e.target.type !== 'range') {
            target = document.getElementById('range')
        } 
        const min = target.min
        const max = target.max
        const val = target.value
        
        target.style.backgroundSize = (val - min) * 100 / (max - min) + '% 100%'
    }

    rangeInputs.forEach(input => {
        input.addEventListener('input', handleInputChange)
    })

    return {
        init: function() {
            range.on("change",              this.rangeChange);
        },
        
        rangeChange: function(){
            if( ($(range).length > 0) ) {
                if($(this).val() >= 5) {
                    var rangeData = $('.range_step_4');
                    var text        = rangeData.data('contacts') + "+";
                    var numberEmail = rangeData.data('mails') + "+";
                    var price       = 0;
                } else {
                    var rangeData = $('.range_step_' + $(this).val());
                    var text        = rangeData.data('contacts');
                    var numberEmail = rangeData.data('mails');
                    var price       = rangeData.data('price') + "€";
                }
                var offre       = $(this).val();    

                $('.offre').removeClass("active");
                $('.offre.offre_'+offre).addClass("active");

                $('.number').text(text);
                $('.number_email').text(numberEmail);
                $('.price').text(price);
            }
        }
    }
}

window.addEventListener("load", function(){
    YP.PricingExeperience().init();
});

YP.FaqAccordion = function() {
    var accordion = $(".accordion_title"),
    accordionFaq = $(".accordion_title_faq"),
    showFaq = $(".js-faq-show"),
    hideFaq = $(".js-faq-hide")
    ;

    accordion.on("click", function(e) {
        e.preventDefault();
        var $this = $(this);

        if (!$this.hasClass("active")) {
            $(".accordion_content").hide();
            $(".accordion_plus_icon").show();
        }

        $this.next().toggle();
        $('.accordion_plus_icon',this).toggle();
    });

    accordionFaq.on("click", function(e) {
        e.preventDefault();
        var $this = $(this);

        if (!$this.hasClass("active")) {
            $(".accordion_content_faq").hide();
            $(".accordion_plus_icon_faq").show();
        }

        $this.next().toggle();
        $('.accordion_plus_icon_faq',this).toggle();
    });

    showFaq.on("click", function(e) {
        e.preventDefault();
        hideFaq.toggle();

        if ($(this).text() == "En voir plus ↓")
        $(this).text("Réduire ↑")
        else
        $(this).text("En voir plus ↓");
    });

}

window.addEventListener("load", function () {
    YP.FaqAccordion();
});

YP.FeatureAccordion = function() {
    var accordion = $(".js-toggle");

    $(".js-toggle_content, .js-image-toggle").hide(); 
    $(".js-toggle_content:first, .js-image-toggle:first").slideDown(200); 
    $(".js-toggle:first").addClass("active"); 

    $(".js-toggle:first .js-image-toggle-icon").hide();

    accordion.on("click", function(e) {
        e.preventDefault();
        var $this = $(this);
        var target = $this.data('target');
        var $content = $('.js-toggle_content[data-target="' + target + '"]');
        var $image = $('.js-image-toggle[data-target="' + target + '"]');
        var $plusIcon = $this.find('.js-image-toggle-icon');

        if ($this.hasClass("active")) {
            return;
        }

        $(".js-toggle_content").slideUp(200);
        $(".js-image-toggle").slideUp(0);
        $(".js-image-toggle-icon").show();
        $(".js-toggle").removeClass("active");

        $content.slideDown(200);
        $image.slideDown(0);
        $plusIcon.hide();
        $this.addClass("active");
    });
}

window.addEventListener("load", function () {
    YP.FeatureAccordion();
});

YP.PricingPageManager = function() {

    var comPercent = 2 / 100,
        comFix = 0.59,
        comFixPremium = 0.49,
        basicPricing = $('.yurplan_basic_pricing'),
        basicTicketPricing = $('.yurplan_ticket_pricing'),
        experiencePricing = $('.yurplan_experience_pricing'),
        experienceTicketPricing = $('.yurplan_experience_ticket_pricing'),
        orgaInput = $('.block-calcul-input-orga'),
        singleTicketCost = $('#block-calcul-ticket-cost'),
        ticketAttendeeCost = $('#block-calcul-attendee-ticket-cost'),
        ypTicketingCost = $('#block-calcul-yp-ticket-cost'),
        ticketPriceInput = $('#block-calcul-input-price'),
        ticketQuantityInput = $('.block-calcul-input-quantity'),
        ticketQuantityValue = $('#block-ticket-quantity'),
        ticketTotalValue = $('.block-ticket-total'),
        attendeeCostInput = $('#block-calcul-attendee-cost'),
        attendeesDiv = $('#block-calcul-input-attendees'),
        showPricingEventManagement = $(".show_pricing_event_management"),
        showPricingMarketing = $(".show_pricing_marketing"),
        showPricingOnSite = $(".show_pricing_on_site"),
        showPricingAccompaniement = $(".show_pricing_accompaniement"),
        pricingEventManagement = $(".pricing_event_management"),
        pricingMarketing = $(".pricing_marketing"),
        pricingOnSite = $(".pricing_on_site"),
        pricingAccompaniement = $(".pricing_accompaniement"),
        showPricingPagesMobile = $(".pricing_nav_input")
        ;
    return {
        init: function() {
            showPricingPagesMobile.change(function() {
                switch($(this).val()) {
                    case "gestion-evenement":
                        YP.PricingPageManager().showPricingEventManagement();
                        break;
                    case "marketing":
                        YP.PricingPageManager().showPricingMarketing();
                        break;
                    case "materiel":
                        YP.PricingPageManager().showPricingOnSite();
                        break;
                    case "accompagnement":
                        YP.PricingPageManager().showPricingAccompaniement();
                        break;
                }
                $('body, html').animate({ scrollTop: $('#' + $(this).val()).position().top }, 0);
                var locAppend = $(this).find('option:selected').val(),
                locSnip   = window.location.pathname.substr(1).split("/");
                window.location.href = locSnip + "#/" + locAppend;
            });

            showPricingEventManagement.on("click",                    function() {showPricingPagesMobile.val('gestion-evenement').change(); });
            showPricingMarketing.on("click",                          function() {showPricingPagesMobile.val('marketing').change(); });
            showPricingOnSite.on("click",                             function() {showPricingPagesMobile.val('materiel').change(); });
            showPricingAccompaniement.on("click",                     function() {showPricingPagesMobile.val('accompagnement').change(); });
            if (location.href.indexOf("gestion-evenement") != -1) {
                showPricingPagesMobile.val('gestion-evenement').change()
            }
            if (location.href.indexOf("marketing") != -1) {
                showPricingPagesMobile.val("marketing").change();
            }
            if (location.href.indexOf("materiel") != -1) {
                showPricingPagesMobile.val("materiel").change();
            }
            if (location.href.indexOf("accompagnement") != -1) {
                showPricingPagesMobile.val("accompagnement").change();
            }

            $(window).scroll(function() {
                if ($(window).scrollTop() >= $('.js_height_nav').height() + 55) {
                    $('.pricing_nav').addClass('active mt-6');
                }
                if ($(window).scrollTop() < $('.js_height_nav').height() + 55){
                    $('.pricing_nav').removeClass('active mt-6');
                }
            })

            ticketQuantityInput.on('input', updateTicketTotalValue);
            ticketPriceInput.on('input', updateTicketTotalValue);
            attendeeCostInput.on('change', updateTicketTotalValue);

            function updateTicketTotalValue(event) {
                ticketQuantity = parseFloat(ticketQuantityInput.val()) || 1;
                if(event.which != 9){
                    ticketCost = parseFloat(ticketPriceInput.val()) || 0;
                    cost = ticketCost * comPercent;
                    if(cost < comFix) {
                        cost = comFix;
                    }
                    if(ticketCost > 0) {
                        attendeesDiv.html(ticketCost+"€");
                        orgaInput.html((ticketCost - cost)+'€');
                        ypTicketingCost.html('-'+(cost)+'€');
                        if(attendeeCostInput.is(':checked')) {
                            ticketTotalValue.html((ticketQuantity * ticketCost).toFixed(2)+'€');
                            ticketAttendeeCost.html((cost + ticketCost).toFixed(2)+'€');
                            singleTicketCost.html((ticketCost).toFixed(2)+'€');
                        }
                        else {
                            ticketTotalValue.html((ticketQuantity * (ticketCost - cost)).toFixed(2)+'€');
                            ticketAttendeeCost.html((ticketCost).toFixed(2)+'€');
                            singleTicketCost.html((ticketCost - cost).toFixed(2)+'€');
                        }
                    }else {
                        orgaInput.html('0€');
                        attendeesDiv.html('0€');
                        ticketTotalValue.html('0€');
                        singleTicketCost.html('0€');
                        ypTicketingCost.html('0€');
                        ticketAttendeeCost.html('0€');
                    }
                }
                if (ticketQuantity > 1){
                    ticketQuantityValue.html((ticketQuantity)+' billets');
                }
                else {
                    ticketQuantityValue.html((ticketQuantity)+' billet');
                }
            };
            
            basicPricing.on('click', function(e){
                e.preventDefault();
                basicPricing.addClass('is-active');
                basicTicketPricing.addClass('is-active');
                experiencePricing.removeClass('is-active');
                experienceTicketPricing.removeClass('is-active');
                $('#pricing_euro').text('0.59€');
                $('#pricing_euro_info').text('');
                ticketPriceInput.keyup(function(event){
                    if(event.which != 9){
                        ticketCost = parseFloat($(this).val()).toFixed(2);
                        cost = parseFloat(ticketCost * comPercent).toFixed(2);
                        if(cost < comFix) {
                            cost = comFix;
                        }
                        if(ticketCost > 0) {
                            attendeesDiv.html(ticketCost+"€");
                            orgaInput.html((ticketCost - cost)+'€');
                        }else {
                            orgaInput.html('0€');
                            attendeesDiv.html('0€');
                        }
                    }
                });
            });

            experiencePricing.on('click', function(e){
                e.preventDefault();
                basicPricing.removeClass('is-active');
                basicTicketPricing.removeClass('is-active');
                experiencePricing.addClass('is-active');
                experienceTicketPricing.addClass('is-active');
                $('#pricing_euro').text('0.49€');
                $('#pricing_euro_info').text('(1)');
                ticketPriceInput.keyup(function(event){
                    if(event.which != 9){
                        ticketCost = parseFloat($(this).val()).toFixed(2);
                        cost = parseFloat(ticketCost * comPercent).toFixed(2);
                        if(cost < comFixPremium) {
                            cost = comFixPremium;
                        }
                        if(ticketCost > 0) {
                            attendeesDiv.html(ticketCost+"€");
                            orgaInput.html((ticketCost - cost)+'€');
                        }else {
                            orgaInput.html('0€');
                            attendeesDiv.html('0€');
                        }
                    }
                });
            });

            function toFixed(num, fixed) {
                var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
                return num.toString().match(re)[0];
            }
        },
        showPricingEventManagement: function(e) {
            pricingEventManagement.show();
            pricingMarketing.hide();
            pricingOnSite.hide();
            pricingAccompaniement.hide();
            showPricingEventManagement.addClass("is-active");
            showPricingMarketing.removeClass("is-active");
            showPricingOnSite.removeClass("is-active");
            showPricingAccompaniement.removeClass("is-active");
        },

        showPricingMarketing: function(e) {
            pricingEventManagement.hide();
            pricingMarketing.show();
            pricingOnSite.hide();
            pricingAccompaniement.hide();
            showPricingEventManagement.removeClass("is-active");
            showPricingMarketing.addClass("is-active");
            showPricingOnSite.removeClass("is-active");
            showPricingAccompaniement.removeClass("is-active");
        },

        showPricingOnSite: function(e) {
            pricingEventManagement.hide();
            pricingMarketing.hide();
            pricingOnSite.show();
            pricingAccompaniement.hide();
            showPricingEventManagement.removeClass("is-active");
            showPricingMarketing.removeClass("is-active");
            showPricingOnSite.addClass("is-active");
            showPricingAccompaniement.removeClass("is-active");
        },

        showPricingAccompaniement: function(e) {
            pricingEventManagement.hide();
            pricingMarketing.hide();
            pricingOnSite.hide();
            pricingAccompaniement.show();
            showPricingEventManagement.removeClass("is-active");
            showPricingMarketing.removeClass("is-active");
            showPricingOnSite.removeClass("is-active");
            showPricingAccompaniement.addClass("is-active");
        },
    }
}

window.addEventListener("load", function(){
    if($('#pricingPageTicketing').length > 0) {
        YP.PricingPageManager().init();
    }
});

YP.MenuDropdown = function() {

    var actionItem = $(".js-topnav--link");
    var linkList = $(".c-yf-topnav__list");
    var linkDropdown = $('.c-yf-topnav__list--link');
    var dropdown = $(".c-yf-topnav__list--dropdown");
    var mobile = $("#js-topnav--toggle");
    var mobileParticipant   = $(".c-header.t-header_nav_participant .c-header__more");

    return {
        init: function() {
            actionItem.on("click",           this.doShowDropdown);
            mobile.on("click",               this.doShowMenuMobile);
            mobileParticipant.on("click",    this.doShowMenuMobileParticipant);
            $(window).scroll(function() {
                if ($(window).scrollTop() >= 30) {
                    $('.c-yf-topnav--pro').css({
                      'border-bottom': '1px solid #F0F2F6'
                    });
                }
                if ($(window).scrollTop() < 30){
                    $('.c-yf-topnav--pro').css({
                        border: 'none'
                    });
                }
            })
        },
        doShowDropdown: function(e){

            var itemMenuDropdown = $(this).parent();

            if(itemMenuDropdown.hasClass('is-visible')){
                itemMenuDropdown.removeClass('is-visible');
                linkDropdown.removeClass('is-hidden');
                dropdown.removeClass('is-visible');
            }
            else{
                actionItem.each(function(){
                    $(this).removeClass('is-visible');
                });
                itemMenuDropdown.addClass('is-visible');
                linkDropdown.addClass('is-hidden');
                dropdown.addClass('is-visible');
            }

        },
        doShowMenuMobile: function(e){
            e.preventDefault();

            var itemMenuDropdown2 = $(".c-yf-topnav");
            if($(this).hasClass('is-active')){
                $(this).removeClass('is-active');
                linkList.removeClass('is-active');
                $(itemMenuDropdown2).removeClass('is-active');
                $(".body-layout-pro").css({
                    'overflow': 'auto',
                    'position': 'inherit'
                });
            }
            else{
                $(this).addClass('is-active');
                linkList.addClass('is-active');
                $(itemMenuDropdown2).addClass('is-active');
                $(".body-layout-pro").css({
                    'overflow': 'hidden',
                    'position': 'fixed'
                });
            }

        },
        doShowMenuMobileParticipant: function(e){
            e.preventDefault();

            var itemMenuDropdown2 = $(".t-header_nav_participant .container_height");

            if($(itemMenuDropdown2).hasClass('is-active')){
                $(itemMenuDropdown2).removeClass('is-active');
            }
            else{
                $(itemMenuDropdown2).addClass('is-active');
            }

        }
    }
}

window.addEventListener("load", function(){
    YP.MenuDropdown().init();
});

$("#search").focusin(function() {
    $("#search-btn").css('display', "block");
});

$(".js-open-modal").click(function(){
    $(".c-modal--yf").addClass("visible");
});
  
$(".js-close-modal").click(function(){
    $(".c-modal--yf").removeClass("visible");
});
  
$(document).click(function(event) {
    if (!$(event.target).closest(".modal,.js-open-modal").length) {
        $("body").find(".c-modal--yf").removeClass("visible");
        $(".c-modal--yf iframe").attr("src", $(".c-modal--yf iframe").attr("src"));
    }
});

var video = $('#js-video-greenland iframe').attr('src');
$('#js-video-greenland img').on("click", function(e) {
    $('#js-video-greenland iframe').attr('src', `${video}&autoplay=1`);
    $(this).hide();
    $("#js-video-greenland iframe").show();
});
